define("discourse/plugins/discourse-ratings/discourse/initializers/initialize-ratings", ["exports", "discourse/models/composer", "discourse/models/category", "discourse/lib/plugin-api", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/plugins/discourse-ratings/discourse/lib/rating-utilities", "I18n", "handlebars", "@ember/application", "@ember/object", "discourse-common/config/environment", "discourse-common/lib/debounce", "@ember/service", "@ember/runloop"], function (_exports, _composer, _category, _pluginApi, _decorators, _computed, _ratingUtilities, _I18n, _handlebars, _application, _object, _environment, _debounce, _service, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const PLUGIN_ID = "discourse-ratings";
  var _default = _exports.default = {
    dialog: (0, _service.inject)(),
    name: "initialize-ratings",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (!siteSettings.rating_enabled) {
        return;
      }
      _composer.default.serializeOnCreate("ratings", "ratingsString");
      _composer.default.serializeOnUpdate("ratings", "ratingsString");
      _composer.default.serializeToDraft("ratings", "ratingsString");
      (0, _pluginApi.withPluginApi)("0.10.0", api => {
        var _dec, _dec2, _dec3, _obj, _dec4, _obj2, _dec5, _obj3, _dec6, _obj4, _dec7, _dec8, _obj5;
        const currentUser = api.getCurrentUser();
        api.includePostAttributes("ratings");
        api.decorateWidget("poster-name:after", function (helper) {
          const post = helper.getModel();
          if (post && post.topic && post.topic.show_ratings && post.ratings) {
            return helper.rawHtml(`${new _handlebars.default.SafeString((0, _ratingUtilities.ratingListHtml)(post.ratings))}`);
          }
        });
        api.reopenWidget("poster-name", {
          buildClasses() {
            const post = this.findAncestorModel();
            let classes = [];
            if (post && post.topic && post.topic.show_ratings && post.ratings) {
              classes.push("has-ratings");
            }
            return classes;
          }
        });
        api.modifyClass("model:composer", (_dec = (0, _decorators.default)("editingPostWithRatings", "topicFirstPost", "post.ratings", "allowedRatingTypes.[]"), _dec2 = (0, _decorators.default)("tags", "category"), _dec3 = (0, _decorators.default)("ratings.@each.{value}"), (_obj = {
          pluginId: PLUGIN_ID,
          editingPostWithRatings: (0, _computed.and)("editingPost", "post.ratings.length"),
          hasRatingTypes: (0, _computed.notEmpty)("ratingTypes"),
          showRatings: (0, _computed.or)("hasRatingTypes", "editingPostWithRatings"),
          ratingTypes(editingPostWithRatings, topicFirstPost, postRatings, allowedRatingTypes) {
            let userCanRate;
            if (this.topic) {
              userCanRate = this.topic.user_can_rate;
            }
            let types = [];
            if (editingPostWithRatings) {
              types.push(...postRatings.map(r => r.type));
            }
            if (topicFirstPost && allowedRatingTypes.length) {
              allowedRatingTypes.forEach(t => {
                if (types.indexOf(t) === -1) {
                  types.push(t);
                }
              });
            } else if (userCanRate && userCanRate.length) {
              userCanRate.forEach(t => {
                if (types.indexOf(t) === -1) {
                  types.push(t);
                }
              });
            }
            return types;
          },
          ratings: (0, _object.computed)("ratingTypes", "editingPostWithRatings", "post.ratings", {
            get() {
              const typeNames = this.site.rating_type_names;
              let result = this.ratingTypes.map(type => {
                let currentRating = (this.post && this.post.ratings || []).find(r => r.type === type);
                let value;
                let include;
                if (this.editingPostWithRatings && currentRating) {
                  value = currentRating.value;
                  include = currentRating.weight > 0 ? true : false;
                }
                let rating = {
                  type,
                  value,
                  include: include !== null ? include : true
                };
                if (typeNames && typeNames[type]) {
                  rating.typeName = typeNames[type];
                }
                return rating;
              });
              return result;
            },
            set(key, value) {
              const typeNames = this.site.rating_type_names;
              let result = this.ratingTypes.map(type => {
                let currentRating = (value || []).find(r => r.type === type);
                let score;
                let include;
                if (this.hasRatingTypes && currentRating) {
                  score = currentRating.value;
                  include = currentRating.value > 0 ? true : false;
                }
                let rating = {
                  type,
                  value: score,
                  include: include !== null ? include : true
                };
                if (typeNames && typeNames[type]) {
                  rating.typeName = typeNames[type];
                }
                return rating;
              });
              return result;
            }
          }),
          allowedRatingTypes(tags, category) {
            const site = this.site;
            let types = [];
            if (category) {
              const categoryTypes = site.category_rating_types[_category.default.slugFor(category)];
              if (categoryTypes) {
                types.push(...categoryTypes);
              }
            }
            if (tags) {
              const tagTypes = site.tag_rating_types;
              if (tagTypes) {
                tags.forEach(t => {
                  if (tagTypes[t]) {
                    types.push(...tagTypes[t]);
                  }
                });
              }
            }
            return types;
          },
          ratingsToSave(ratings) {
            return ratings.map(r => ({
              type: r.type,
              value: r.value,
              weight: r.include ? 1 : 0
            }));
          },
          ratingsString: (0, _object.computed)("ratingsToSave.@each.{value}", {
            get() {
              return JSON.stringify(this.ratingsToSave);
            },
            set(key, value) {
              if (value) {
                const typeNames = this.site.rating_type_names;
                const draftRatings = JSON.parse(value).map(r => {
                  return {
                    type: r.type,
                    value: r.value,
                    typeName: typeNames[r.type],
                    include: true
                  };
                });
                this.set("ratings", draftRatings);
              }
              let result = value || JSON.stringify(this.ratingsToSave);
              return result;
            }
          })
        }, (_applyDecoratedDescriptor(_obj, "ratingTypes", [_dec], Object.getOwnPropertyDescriptor(_obj, "ratingTypes"), _obj), _applyDecoratedDescriptor(_obj, "allowedRatingTypes", [_dec2], Object.getOwnPropertyDescriptor(_obj, "allowedRatingTypes"), _obj), _applyDecoratedDescriptor(_obj, "ratingsToSave", [_dec3], Object.getOwnPropertyDescriptor(_obj, "ratingsToSave"), _obj)), _obj)));
        api.modifyClass("controller:composer", (_dec4 = (0, _decorators.observes)("model.reply", "model.title", "model.ratings.@each.{value}"), (_obj2 = {
          pluginId: PLUGIN_ID,
          save(ignore, event) {
            const model = this.model;
            const ratings = model.ratings;
            const showRatings = model.showRatings;
            if (showRatings && ratings.some(r => r.include && !r.value)) {
              return this.dialog.alert(_I18n.default.t("composer.select_rating"));
            }
            return this._super(ignore, event);
          },
          _shouldSaveDraft() {
            if (this.model && !this.model.loading && !this.skipAutoSave && !this.model.disableDrafts) {
              if (!this._lastDraftSaved) {
                // pretend so we get a save unconditionally in 15 secs
                this._lastDraftSaved = Date.now();
              }
              if (Date.now() - this._lastDraftSaved > 15000) {
                this._saveDraft();
              } else {
                let method = (0, _environment.isTesting)() ? _runloop.run : _debounce.default;
                this._saveDraftDebounce = method(this, this._saveDraft, 2000);
              }
            }
          }
        }, (_applyDecoratedDescriptor(_obj2, "_shouldSaveDraft", [_dec4], Object.getOwnPropertyDescriptor(_obj2, "_shouldSaveDraft"), _obj2)), _obj2)));
        api.registerCustomPostMessageCallback("ratings", (controller, data) => {
          const model = controller.get("model");
          const typeNames = controller.site.rating_type_names;
          data.ratings.forEach(r => {
            if (typeNames && typeNames[r.type]) {
              r.type_name = typeNames[r.type];
            }
          });
          model.set("ratings", data.ratings);
          model.get("postStream").triggerChangedPost(data.id, data.updated_at).then(() => {
            controller.appEvents.trigger("post-stream:refresh", {
              id: data.id
            });
          });
          if (data.user_id === currentUser.id) {
            model.set("user_can_rate", data.user_can_rate);
          }
          controller.appEvents.trigger("header:update-topic", model);
        });
        api.modifyClass("component:topic-list-item", (_dec5 = (0, _decorators.default)("topic", "lastVisitedTopic", "hasRatings"), (_obj3 = {
          pluginId: PLUGIN_ID,
          hasRatings: (0, _computed.and)("topic.show_ratings", "topic.ratings"),
          unboundClassNames(topic, lastVisitedTopic, hasRatings) {
            let classes = this._super(topic, lastVisitedTopic) || "";
            if (hasRatings) {
              classes += " has-ratings";
            }
            return classes;
          }
        }, (_applyDecoratedDescriptor(_obj3, "unboundClassNames", [_dec5], Object.getOwnPropertyDescriptor(_obj3, "unboundClassNames"), _obj3)), _obj3)));
        api.modifyClass("component:topic-title", (_dec6 = (0, _decorators.on)("init"), (_obj4 = {
          pluginId: PLUGIN_ID,
          hasRatings: (0, _computed.alias)("model.show_ratings"),
          editing: (0, _computed.alias)("topicController.editingTopic"),
          hasTags: (0, _computed.notEmpty)("model.tags"),
          showTags: (0, _computed.and)("hasTags", "siteSettings.tagging_enabled"),
          hasFeaturedLink: (0, _computed.notEmpty)("model.featured_link"),
          showFeaturedLink: (0, _computed.and)("hasFeaturedLink", "siteSettings.topic_featured_link_enabled"),
          hasExtra: (0, _computed.or)("showTags", "showFeaturedLink"),
          classNameBindings: ["hasRatings", "editing", "hasExtra"],
          setupController() {
            const topicController = (0, _application.getOwner)(this).lookup("controller:topic");
            this.set("topicController", topicController);
          }
        }, (_applyDecoratedDescriptor(_obj4, "setupController", [_dec6], Object.getOwnPropertyDescriptor(_obj4, "setupController"), _obj4)), _obj4)));
        api.modifyClass("component:composer-body", (_dec7 = (0, _decorators.observes)("composer.showRatings"), _dec8 = (0, _decorators.on)("didRender"), (_obj5 = {
          pluginId: PLUGIN_ID,
          resizeIfShowRatings() {
            if (this.get("composer.viewOpen")) {
              this._triggerComposerResized();
            }
          },
          addContainerClass() {
            if (!this.element || this.isDestroying || this.isDestroyed) {
              return;
            }
            if (this.composer && this.composer.showRatings) {
              if (!this.element.classList.contains("reply-control-ratings")) {
                this.element.classList.add("reply-control-ratings");
              }
            }
          }
        }, (_applyDecoratedDescriptor(_obj5, "resizeIfShowRatings", [_dec7], Object.getOwnPropertyDescriptor(_obj5, "resizeIfShowRatings"), _obj5), _applyDecoratedDescriptor(_obj5, "addContainerClass", [_dec8], Object.getOwnPropertyDescriptor(_obj5, "addContainerClass"), _obj5)), _obj5)));
      });
    }
  };
});