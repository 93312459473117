define("discourse/plugins/discourse-ratings/discourse/components/rating-object", ["exports", "@ember/component", "discourse-common/utils/decorators", "@ember/object/computed", "discourse/models/category", "I18n"], function (_exports, _component, _decorators, _computed, _category, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("error", "object.name", "object.types.[]"), (_obj = {
    classNameBindings: [":rating-object", ":admin-ratings-list-object", "error:hasError"],
    tagName: "tr",
    isCategory: (0, _computed.equal)("objectType", "category"),
    isTag: (0, _computed.equal)("objectType", "tag"),
    error: null,
    didReceiveAttrs() {
      const object = this.object;
      this.setProperties({
        currentName: object.name,
        currentTypes: object.types
      });
      if (object.name) {
        if (this.isCategory) {
          const slugPath = object.name.split("/");
          this.set("category", _category.default.findBySlugPath(slugPath));
        }
        if (this.isTag) {
          this.set("tag", object.name);
        }
      }
    },
    saveDisabled(error, objectName, objectTypes) {
      return error || !objectName || !objectTypes.length || objectName === this.currentName && JSON.stringify(objectTypes) === JSON.stringify(this.currentTypes);
    },
    actions: {
      updateCategory(categoryId) {
        const category = _category.default.findById(categoryId);
        const slug = _category.default.slugFor(category);
        const objects = this.objects || [];
        if (objects.every(o => o.name !== slug)) {
          this.setProperties({
            "object.name": slug,
            category,
            error: null
          });
        } else {
          this.set("error", _I18n.default.t("admin.ratings.error.object_already_exists", {
            objectType: this.objectType
          }));
        }
      },
      updateTag(tags) {
        const objects = this.objects || [];
        const tag = tags[0];
        if (objects.every(o => o.name !== tag)) {
          this.setProperties({
            "object.name": tag,
            tag,
            error: null
          });
        } else {
          this.set("error", _I18n.default.t("admin.ratings.error.object_already_exists", {
            objectType: this.objectType
          }));
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "saveDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "saveDisabled"), _obj)), _obj)));
});