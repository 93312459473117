define("discourse/plugins/discourse-ratings/discourse/components/rating-migrate", ["exports", "discourse-common/utils/decorators", "discourse/plugins/discourse-ratings/discourse/models/rating", "@ember/component"], function (_exports, _decorators, _rating, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const noneType = "none";
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("categoryId", "toType", "fromType"), (_obj = {
    classNames: ["rating-action-controls"],
    migrateDisabled(categoryId, toType, fromType) {
      return [categoryId, toType, fromType].any(i => !i) || toType !== noneType && fromType !== noneType;
    },
    actions: {
      migrate() {
        let data = {
          category_id: this.categoryId,
          type: this.fromType,
          new_type: this.toType
        };
        this.set("startingMigration", true);
        _rating.default.migrate(data).then(result => {
          if (result.success) {
            this.set("migrationMessage", "admin.ratings.migrate.started");
          } else {
            this.set("migrationMessage", "admin.ratings.error.migration_failed_to_start");
          }
        }).finally(() => this.set("startingMigration", false));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "migrateDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "migrateDisabled"), _obj)), _obj)));
});